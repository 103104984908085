// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `login-form {
  display: block;
}

.login-form--Form > * {
  margin-top: 25px;
}

.login-form--ForgotPassword {
  text-align: right;
  font-weight: 500;
  margin-top: 24px;
}

.login-form--BottomLink {
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.login-form--BottomLink > a {
  display: inline-block;
  margin-top: 10px;
}

.login-form--ForgotPassword > a,
.login-form--ForgotPassword > a:visited,
.login-form--BottomLink > a,
.login-form--BottomLink > a:visited {
  color: var(--record-it-color-secondary);
  opacity: 0.5;
}

.login-form--ForgotPassword > a:hover,
.login-form--BottomLink > a:hover {
  opacity: 0.8;
}

.login-form--LoadingPreloader {
  position: relative;
}

.login-form--Label {
  opacity: 0.6;
  font-size: 12px;
}

.login-form--ServerItem {
  display: flex;
  align-items: center;
  height: 52px;
  gap: 16px;
  cursor: pointer;
  padding: 5px;
}

.login-form--ServerItem:hover {
  background-color: var(--record-it-color-gray-500);
}

.login-form--ServerNameContainer {
  flex-grow: 1;
}

.login-form--ServerNameContainer {
  display: flex;
  flex-direction: column;
}

.login-form--MainServerLoginInfo {
  text-align: center;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/login-form/login-form.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;;;;EAIE,uCAAuC;EACvC,YAAY;AACd;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,SAAS;EACT,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["login-form {\n  display: block;\n}\n\n.login-form--Form > * {\n  margin-top: 25px;\n}\n\n.login-form--ForgotPassword {\n  text-align: right;\n  font-weight: 500;\n  margin-top: 24px;\n}\n\n.login-form--BottomLink {\n  font-weight: 500;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.login-form--BottomLink > a {\n  display: inline-block;\n  margin-top: 10px;\n}\n\n.login-form--ForgotPassword > a,\n.login-form--ForgotPassword > a:visited,\n.login-form--BottomLink > a,\n.login-form--BottomLink > a:visited {\n  color: var(--record-it-color-secondary);\n  opacity: 0.5;\n}\n\n.login-form--ForgotPassword > a:hover,\n.login-form--BottomLink > a:hover {\n  opacity: 0.8;\n}\n\n.login-form--LoadingPreloader {\n  position: relative;\n}\n\n.login-form--Label {\n  opacity: 0.6;\n  font-size: 12px;\n}\n\n.login-form--ServerItem {\n  display: flex;\n  align-items: center;\n  height: 52px;\n  gap: 16px;\n  cursor: pointer;\n  padding: 5px;\n}\n\n.login-form--ServerItem:hover {\n  background-color: var(--record-it-color-gray-500);\n}\n\n.login-form--ServerNameContainer {\n  flex-grow: 1;\n}\n\n.login-form--ServerNameContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.login-form--MainServerLoginInfo {\n  text-align: center;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
