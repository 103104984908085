// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth--FloatingLabelInput + .auth--FloatingLabelInput {
  margin-top: 25px;
}

.auth--BottomLink {
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.auth--BottomLink > a {
  display: inline-block;
  margin-top: 10px;
}

.auth--BottomLink > a,
.auth--BottomLink > a:visited {
  color: var(--record-it-color-secondary);
  opacity: 0.5;
}

.auth--BottomLink > a:hover {
  opacity: 0.8;
}

.auth--CheckboxesContainer {
  margin-top: 40px;
  hyphens: auto;
}

.auth--CheckboxContainer {
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-top: 24px;
}

.auth--SelectedServerInfo {
  text-align: center;
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/auth.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;;EAEE,uCAAuC;EACvC,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".auth--FloatingLabelInput + .auth--FloatingLabelInput {\n  margin-top: 25px;\n}\n\n.auth--BottomLink {\n  font-weight: 500;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.auth--BottomLink > a {\n  display: inline-block;\n  margin-top: 10px;\n}\n\n.auth--BottomLink > a,\n.auth--BottomLink > a:visited {\n  color: var(--record-it-color-secondary);\n  opacity: 0.5;\n}\n\n.auth--BottomLink > a:hover {\n  opacity: 0.8;\n}\n\n.auth--CheckboxesContainer {\n  margin-top: 40px;\n  hyphens: auto;\n}\n\n.auth--CheckboxContainer {\n  display: flex;\n  align-items: baseline;\n  gap: 16px;\n  margin-top: 24px;\n}\n\n.auth--SelectedServerInfo {\n  text-align: center;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
